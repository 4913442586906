<template>
  <b-card>
    <b-card-header>
      <h2>
        <feather-icon
          icon="EditIcon"
          size="16"
        />
        {{ $t("Routes.addCategory") }}
      </h2>
    </b-card-header>
    <b-card-body>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <form-input
                v-model="categoria.nombre"
                :label="$t('AppHelpDesk.categoryColumns.name')"
                validation-rules="required"
                :get-validation-state="getValidationState"
                :disabled="!$can('write', 'MesaAyuda')"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <form-input
                v-model="categoria.abreviatura"
                :label="$t('AppHelpDesk.categoryColumns.abbreviation')"
                validation-rules="required"
                :get-validation-state="getValidationState"
                :disabled="!$can('write', 'MesaAyuda')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('AppHelpDesk.categoryColumns.parentCategoria')"
                label-for="assigned"
              >
                <v-select
                  id="assigned"
                  v-model="categoria.categoriaPadreId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="categories"
                  :reduce="(option) => option.ticketCategoriaId"
                  :disabled="!$can('write', 'MesaAyuda')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                :name="$t('AppHelpDesk.categoryDescription')"
                rules="required|max:1025"
              >
                <b-form-group
                  :label="$t('AppHelpDesk.categoryDescription')"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="categoria.descripcion"
                    rows="3"
                    :placeholder="$t('AppHelpDesk.placeHolder')"
                    :disabled="!$can('write', 'MesaAyuda')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="$can('write', 'MesaAyuda')"
            class="float-right mt-2 mb-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner
                v-if="addingCategory"
                small
                class="mr-1"
              />
              {{ $t("Lists.Add") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import FormInput from '@/components/FormInput.vue'
import ticketService from '@/services/helpDesk.service'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    FormInput,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const addingCategory = ref(false)
    const categories = ref([])
    const categoria = ref({
      ticketCategoriaId: 0,
      nombre: '',
      descripcion: '',
      categoriaPadreId: null,
      abreviatura: '',
      ruta: '',
    })
    const resetTicketData = () => {
      categoria.value = {
        ticketCategoriaId: 0,
        nombre: '',
        descripcion: '',
        categoriaPadreId: null,
        abreviatura: '',
        ruta: '',
      }
    }

    const {
      fetchCategorias,
      createCategory,
    } = ticketService()

    fetchCategorias({}, data => {
      categories.value = data.categorias
    })

    const {
      getValidationState,
    } = formValidation(resetTicketData)
    const onSubmit = () => {
      addingCategory.value = true
      createCategory(categoria.value)
        .then(() => {
          addingCategory.value = false
          router.push({ name: 'apps-categories-list' })
        })
        .catch(() => {
          addingCategory.value = false
        })
    }
    return {
      categories,
      categoria,
      addingCategory,
      getValidationState,
      onSubmit,
    }
  },

}

</script>
